<template>
  <div class="orgchart-list-item">
    <div class="grid">
      <div class="box">
        <div class="orgchart-list-user">
          <a
            v-if="user.profile_url"
            :href="user.profile_url"
            class="orgchart-list-avatar"
            :style="`background-image: url(${avatarUrl(user.image)});`"
            target="_blank"
          ></a>
          <p
            v-else
            class="orgchart-list-avatar"
            :style="`background-image: url(${avatarUrl(user.image)});`"
          ></p>
          <a
            v-if="user.profile_url"
            :href="user.profile_url"
            class="orgchart-list-name"
            target="_blank"
            >{{ user.name ? translateText(user, "name") : "Test" + user.id }}</a
          >
          <p v-else class="orgchart-list-name">
            {{ user.name ? translateText(user, "name") : "Test" + user.id }}
          </p>
        </div>
      </div>
      <div class="box">
        <div
          class="orgchart-list-extra-block tooltip-toggle"
          v-if="user.infoText"
        >
          <div
            class="orgchart-list-extra-icon"
            @click="showInfoText = !showInfoText"
          ></div>
          <div class="tooltip-content" v-show="showInfoText">
            <div class="tooltip-remove" @click="showInfoText = false"></div>
            <div class="text">
              <p v-html="user.infoText"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="orgchart-list-mate-block" v-if="user.deputies.length > 0">
          <div class="orgchart-list-mate-pretitle">{{ $t("user.replace") }}:</div>
          <div class="orgchart-list-mate-group">
            <replace-item
              v-for="item in user.deputies"
              :key="item.id"
              :user="item"
            />
          </div>
        </div>
      </div>
      <div class="box">
        <div class="orgchart-list-info-group">
          <div
            class="orgchart-list-info-item"
            v-for="(el, index) in userDepartments"
            :key="index"
          >
            {{ el }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReplaceItem from '../components/ReplaceItem.vue';
export default {
  name: 'ListItem',
  components: {
    ReplaceItem,
  },
  props: ['user'],

  data() {
    return {
      showInfoText: false,
      showUserReplace: false,
      urlRoute: process.env.VUE_APP_ROOT_URL,
      isDev: process.env.VUE_APP_IS_DEV,
    };
  },

  computed: {
    userDepartments() {
      const translate = this.translateText(this.user, 'title');
      const arr = translate.split('/');
      return arr.map(el => el.trim());
    },
  },

  methods: {
    translateText(obj, key) {
      if (this.$i18n.locale === 'en') {
        return obj[key];
      }
      if (this.$i18n.locale === 'ru') {
        return obj[key + '_ru'];
      }
    },
    avatarUrl(url) {
      if (this.isDev === 'false' && !url.includes('no-ava')) {
        return this.urlRoute + url;
      }
      return url;
    },
  },
};
</script>
