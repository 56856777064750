<template>
  <div>
    <div class="home">
      <transition name="fade">
        <div v-if="isLoading" class="load-overlay">{{ $t("message.loading") }}...</div>
      </transition>
      <div id="chart-container"></div>
    </div>
  </div>
</template>

<script>
import * as $ from 'jquery';
export default {
  name: 'Chart',
  components: {},

  props: {
    users: {
      type: Object,
      default: null,
    },
    userId: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      isLoading: true,
      urlRoute: process.env.VUE_APP_ROOT_URL,
      isDev: process.env.VUE_APP_IS_DEV,      
    };
  },
  // computed: {
  //   isRu() {
  //     return this.$i18n.locale === 'ru';
  //   },
  // },

  mounted() {    
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;

    const chartContainer = $('#chart-container').orgchart({
      data: this.users,
      pan: true,
      zoom: false,
      createNode(node, data) {
        const el = node[0].querySelector('.title');
        node[0].classList.add('node' + data.id);
        el.classList.remove('title');

        setTimeout(() => {
          const arrowLeft = node[0].querySelector('.leftEdge');
          const arrowRight = node[0].querySelector('.rightEdge');
          if (arrowLeft && arrowRight) {
            arrowLeft.classList.add('customLeftArrow');
            arrowRight.classList.add('customRightArrow');
          }
        }, 300);

        el.innerHTML = _this.getTemplateNode(data);

        // Обработчики для внутренних элементов
        if (el.querySelector('.button')) {
          el.querySelector('.button').addEventListener('click', function() {
            const text = el.querySelector('.hiddenText');
            text.classList.toggle('hide');
          });
        }
      },
    });

    chartContainer.$chart.on('init.orgchart', function() {
      setTimeout(() => {
        chartContainer.hideParent($(`#${_this.userId}`));
        chartContainer.hideChildren($(`#${_this.userId}`));
        setTimeout(() => {
          _this.isLoading = false;
        }, 300);
      });
    });

    // Центрируем на элементе при клике на кнопки left/right/bottom
    const centerNode = function(el) {
      if (el.classList.contains('active-node') || !el.hasAttribute('data-parent')) {
        $('.orgchart').removeAttr('style');
      }
      $('.node').removeClass('active-node');
      setTimeout(() => {
        el.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
        el.classList.add('active-node');
      }, 100);
    };
    $('#chart-container')
      .find('.bottomEdge')
      .on('click', function() {
        centerNode($(this).parent('.node')[0]);
      });
    $('#chart-container')
      .find('.rightEdge')
      .on('click', function() {
        centerNode($(this).parent('.node')[0]);
      });
    $('#chart-container')
      .find('.leftEdge')
      .on('click', function() {
        centerNode($(this).parent('.node')[0]);
      });

    // Раскрываем все соседние ноды при клике наверх
    $('#chart-container')
      .find('.topEdge')
      .on('click', function() {
        if ($(this).hasClass('oci-chevron-up')) {
          const parentId = $(this)
            .parent()
            .attr('id');
          chartContainer.showSiblings($(`#${parentId}`));
          centerNode($(this).parent('.node')[0]);
        }
        $('.orgchart').css('transform', '');
      });

    $('#chart-container')
      .find('.replaceItem')
      .on('click', function() {
        $('.replaceItem')
          .not(this)
          .removeClass('active');
        $(this).toggleClass('active');

        const cur = $(this).find('.tooltip-content__chart');
        $('.tooltip-content__chart')
          .not(cur)
          .addClass('hide');
        $(this)
          .find('.tooltip-content__chart')
          .toggleClass('hide');
      });

    /**
     * Закрываем блок с информацией о замещающем сотруднике
     */
    $('#chart-container')
      .find('.replaceItemClose')
      .on('click', function(event) {
        event.stopPropagation();
        $(this)
          .parent()
          .addClass('hide');
        $(this)
          .parents('.replaceItem')
          .removeClass('active');
      });
  },

  methods: {
    getTemplateNode(data) {
      const num = String(Math.random());
      let templateNode;
      let avatarTemplate;
      if (data.profile_url.length !== 0) {
        templateNode = `
          <div class="node-content">            
            <a href="${data.profile_url}" target="_blank" class="node-title">${data.name ? this.translateText(data, 'name') : 'Test' + num.slice(0, 5)}</a>
            <p class="node-status">${this.translateText(data, 'title', true)}</p>
          </div>`;

        avatarTemplate = `
        <a href="${
          data.profile_url
        }" target="_blank" class="avatar">            
          <img src="${this.avatarUrl(data.image)}" alt=""/>
        </a>`;
      } else {
        templateNode = `
          <div class="node-content">
            <p class="node-title">${data.name ? this.translateText(data, 'name') : 'Test' + num.slice(0, 5)}</p>
            <p class="node-status">${this.translateText(data, 'title', true)}</p>
          </div>`;
        avatarTemplate = `
          <div class="avatar">
            <img src="${this.avatarUrl(data.image)}" alt=""/>
          </div>`;
      }

      const additionalContentTemplate = `
        <div class="infoText">
            <button class="button">Клик</button>
            <p class="hiddenText hide">
                ${data.infoText}
            </p>
        </div>
      `;

      if (data.image) {
        templateNode = avatarTemplate + templateNode;
      }

      if (data.infoText) {
        templateNode = additionalContentTemplate + templateNode;
      }

      if (data.deputies.length > 0) {
        const replaceTemplate = `
          <div class="hr"></div>  
          <div class="zam">
              <div class="zam-subtitle">${this.$t('user.replace')}:</div>
              ${this.createReplaceUsers(data.deputies)}
          <div>
        `;
        templateNode += replaceTemplate;
      }

      return templateNode;
    },

    /**
     * создание блока 'Замещают' в кастомной ноде
     */
    createReplaceUsers(data) {
      let result = '';
      let userUrl = '';
      for (let i = 0; i < data.length; i++) {
        if (data[i].profile_url) {
          userUrl = `<a href=${data[i].profile_url}} class="orgchart-list-name" target="_blank">${this.translateText(data[i], 'name')}</a>`;
        } else {
          userUrl = `<p class="orgchart-list-name" >${this.translateText(data[i], 'name')}</p>`;
        }
        result += `
          <div class="zam-item replaceItem">
            <img src="${this.avatarUrl(
              data[i].image,
            )}" alt="" class="zam-avatar">
            <div class="tooltip-content tooltip-content__chart hide">
              <div class="tooltip-remove replaceItemClose"></div>
              ${userUrl}
              <div class="orgchart-list-info-group">
                  <div class="orgchart-list-info-item">${this.translateText(data[i], 'title', false)}</div>                  
              </div>
            <div class="orgchart-list-mate-notice">${
              data[i].infoText ? data[i].infoText : ''
            }</div>
            </div>          
          </div>`;
      }
      return result;
    },
    dutiesFormat(txt) {
      if (txt && txt !== '') {
        return txt.replace(/[/]/g, '<br />');
      }
      return '';
    },

    translateText(obj, key, duties) {
      if (this.$i18n.locale === 'en') {
        if (key === 'title' && duties) {
          return this.dutiesFormat(obj[key]); 
        }
        return obj[key];
      }
      if (this.$i18n.locale === 'ru') {
        if (key === 'title' && duties) {
          return this.dutiesFormat(obj[key + '_ru']); 
        }             
        return obj[key + '_ru'];        
      }
    },

    avatarUrl(url) {
      if (this.isDev === 'false' && !url.includes('no-ava')) {
        return this.urlRoute + url;
      }
      return url;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
