<template>
  <header class="header">
    <div class="content">
      <div class="grid">
        <div class="box">
          <a href="https://seldico.ru/" class="button-item hollow sm">
            <span class="icon-item icon-back"></span>
            {{ $t("title.back") }}
          </a>
        </div>
        <div class="box">
          <h1 class="orgchart-title">{{ $t("title.main") }}</h1>
        </div>
        <div class="box">
          <div class="lang__group">
            <a @click.prevent="changeLocale('ru')" class="lang__item lang__item--ru" :class="{'lang__item--active': $i18n.locale === 'ru'}"></a>
            <a @click.prevent="changeLocale('en')" class="lang__item lang__item--en" :class="{'lang__item--active': $i18n.locale === 'en'}"></a>
          </div>
          <a href="https://seldico.ru/" target="_blank" class="logo"></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',

  methods: {
    changeLocale(loc) {
      localStorage.setItem('locale', loc);      
      this.$store.dispatch('getOrgchart');
      this.$i18n.locale = loc;
    },
  },
};
</script>
