<template>
  <div class="orgchart-list-mate-item tooltip-toggle">
    <div
      class="orgchart-list-mate-avatar"
      :style="userAvatar"
      @click="showInfo = !showInfo"
    ></div>
    <div class="tooltip-content" v-show="showInfo">
        <div class="tooltip-remove" v-show="showInfo" @click="showInfo = false"></div>
        <a :href="user.profile_url" class="orgchart-list-name" target="_blank">{{ translateText(user, "name") }}</a>
        <div class="orgchart-list-info-group">
            <div class="orgchart-list-info-item">{{ translateText(user, "title") }}</div>            
        </div>
        <div class="orgchart-list-mate-notice" v-if="user.info_text">{{ user.info_text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReplaceItem',
  props: ['user'],

  data() {
    return {
      showInfo: false,
    };
  }, 
  computed: {    
    userAvatar() {
      return `background-image:url(${this.user.image})`;
    },
  }, 
  methods: {
    translateText(obj, key) {
      if (this.$i18n.locale === 'en') {
        return obj[key];
      }
      if (this.$i18n.locale === 'ru') {
        return obj[key + '_ru'];
      }
    },
  },
};
</script>
