<template>
  <div class="orgchart-list-block">
    <div class="content" v-if="users.length > 0">
      <list-item v-for="user in users" :key="user.id" :user="user" />
    </div>
    <div v-else class="content">
      <p>{{ $t("message.emptyEmpl") }}</p>
    </div>
  </div>
</template>

<script>
import ListItem from '../components/ListItem.vue';
export default {
  name: 'List',
  components: {
    ListItem,
  },
  props: {
    users: {
      type: Array,
      default: null,
    },    
  }, 
};
</script>
