<template>
<div>
  <header-page />
  <div class="body">
    <div class="orgchart-header">
      <div class="content">
        <div class="orgchart-subtitle">{{ $t("title.filter") }}:</div>
        <div class="orgchart-filter-group">
          <a class="orgchart-filter-item" :class="{active: currentTab === 'all'}" @click="setActiveTab('all')">{{ $t("title.alTabs") }}</a>
          <a 
            v-for="item in specializations"
            :key="item.id"
            class="orgchart-filter-item"
            :class="{active: currentTab === `tab${item.id}`}"
            @click="setActiveTab(item.id)"
          >{{ $i18n.locale === 'en' ? item.name_eng : item.name }}</a>          
        </div>
      </div>
    </div>
    <chart
      v-if="usersChart"
      v-show="currentTab === 'all'"
      :users="usersChart"
      :userId="currentUser"
      :key="chartRenderKey"
    />    
    <list
      v-show="currentTab !== 'all'"
      :users="usersList"      
     />
  </div>
</div>
    
  
</template>

<script>
import Users from '@/api/users';
import {mapGetters} from 'vuex';
import HeaderPage from '../components/Header.vue';
import Chart from '../components/Chart.vue';
import List from '../components/List.vue';

export default {
  name: 'Main',

  components: {
    HeaderPage,
    Chart,
    List,
  },

  data() {
    return {
      currentTab: 'all',
      specializations: [],
      usersList: [],
      chartRenderKey: 0,
    };
  },
  computed: {
    ...mapGetters(['usersChart', 'userId', 'isUserInOrgchart', 'firstUserOrgchart', 'userToken']),
    currentUser() {
      const isLocal = this.isUserInOrgchart === 'false';
      return Number(!isLocal ? this.userId : this.firstUserOrgchart);
    },
  },

  watch: {
    usersChart() {
      if (this.currentTab === 'all') {
        this.chartRenderKey += 1;
      }      
    },
  },

  async created() {
    await Users.getSpecializations(this.userToken).then(result => {
      this.specializations = result.data;
    });
    window.history.replaceState({}, document.title, '/');
  },

  methods: {
    setActiveTab(current) {
      if (current !== 'all') {
        this.currentTab = `tab${current}`;
        Users.getListUsers(this.userToken, current).then(result => {
          this.usersList = result.data;        
        });
        return;        
      }
      this.currentTab = 'all';
      this.chartRenderKey += 1;
    },
    // translateText(obj, key) {
    //   if (this.$i18n.locale === 'en') {
    //     return obj[key];
    //   }
    //   if (this.$i18n.locale === 'ru') {
    //     return obj[key + '_ru'];
    //   }
    // },
  },
};
</script>


